<template>

  <b-row>

    <!-- Left Col: Card -->
    <b-col
      cols="12"
      md="4"
      xl="3"
      class="invoice-actions"
    >

      <b-badge
        variant="primary"
        class="d-block p-75 mb-1"
      >
        <span class="mb-0 text-white">Submission # {{ userInfo.id }}</span>
      </b-badge>

      <b-card
        no-body
      >

        <b-card-header class="d-flex justify-content-between align-items-center">
          <h5>
            {{ camperStudent(1) }} Profile
          </h5>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled">
            <li>
              <span class="align-middle">Name: {{ userInfo.full_name }}</span>
            </li>
            <li class="my-25">
              <span class="align-middle">DOB: </span>
            </li>
            <li class="my-25">
              <span class="align-middle">Gender: {{ userInfo.extra_user_info.gender }}</span>
            </li>
            <li class="mt-25">
              <span class="align-middle">Parent ID:
                <b-badge
                  variant="light-primary"
                ># </b-badge>
              </span>
            </li>
          </ul>
        </b-card-body>

      </b-card>

    </b-col>

    <!-- Right Col: -->
    <b-col
      cols="12"
      xl="9"
      md="8"
    >

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <ApplicationAddRow
          v-for="(item, index) in fieldsList"
          :key="item.frontID"
          :fields-data="item"
          :field-index="index"
        />

        <div
          class="card-footer"
        >
          <b-button
            variant="primary"
            class="mr-2"
            @click="saveForm"
          >
            Save Changes
          </b-button>
          <b-button
            variant="outline-primary"
            @click="resetForm"
          >
            Reset
          </b-button>
        </div>
      </b-card>

    </b-col>

  </b-row>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import { formBuilderPreparations } from '@core/mixins/formBuilderPreparations'

import Ripple from 'vue-ripple-directive'
import store from '@/store'
import storeModule from '@/views/admin/applications/applicationsStoreModule'
import useApplicationView from '@/views/admin/applications/application-view/useApplicationView'
import router from '@/router'

import ApplicationAddRow from '@/views/admin/applications/application-view/ApplicationViewRow.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BBadge,
    BButton,

    ApplicationAddRow,
  },
  directives: {
    Ripple,
  },
  mixins: [formBuilderPreparations],
  data() {
    return {
      groupDetail: {},
      setProgram: null,
      type: null,
      fieldsList: [],
      defaultFieldsList: [],
      userInfo: {
        extra_user_info: {},
      },
      fieldsValue: [],
    }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-applications'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchApplication,
      fetchProgram,
      fetchForm,
      editCamperApplication,
      editStaffApplication,
    } = useApplicationView()

    return {
      fetchApplication,
      fetchProgram,
      fetchForm,
      editCamperApplication,
      editStaffApplication,
    }
  },
  watch: {
    setProgram(val) {
      if (!val) return

      this.fetchForm(val.form_id)
        .then(response => {
          this.defaultFieldsList = response.fields.map(row => ({
            id: this.fieldsValue.filter(item => item.field_id === row.id)[0].id,
            field_id: row.id,
            value: this.fieldsValue.filter(item => item.field_id === row.id)[0].result,
            label: row.label,
            name: row.name,
            options: this.designateOptions(row.type, row.options),
            type: this.transformField(row.type),
            required: row.required,
            disabled: true,
          }))
          this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
        })
    },
  },
  async created() {
    this.type = router.currentRoute.params.type
    const applicationID = router.currentRoute.params.id
    let programID = null

    if (applicationID) {
      await this.fetchApplication(applicationID).then(response => {
        programID = response.program_id
        this.userInfo = response.user
        this.fieldsValue = response.application_fields
      })
    }

    if (programID) {
      await this.fetchProgram(programID).then(response => {
        this.setProgram = response
        this.setProgram.form_id = response.forms.filter(form => form.type === this.type)[0]?.id
      })
    }
  },
  methods: {
    resetForm() {
      this.fieldsList = JSON.parse(JSON.stringify(this.defaultFieldsList))
    },
    async saveForm() {
      const queryParams = {
        fields: [],
        form_data: {},
      }

      this.fieldsList.forEach(row => {
        queryParams.fields.push({
          id: row.id,
          field_id: row.field_id,
          result: row.value,
        })
        queryParams.form_data[row.name] = row.value
      })

      if (['Camper', 'Student'].includes(this.type)) {
        await this.editCamperApplication(router.currentRoute.params.id, queryParams)
        return
      }

      await this.editStaffApplication(router.currentRoute.params.id, queryParams)
    },
  },
}
</script>

<style scoped>
</style>
